import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "text-center mt-6 text-black-50" }
const _hoisted_3 = { class: "d-grid mt-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageField = _resolveComponent("ImageField")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_BtnResource = _resolveComponent("BtnResource")!
  const _component_WaitForResource = _resolveComponent("WaitForResource")!

  return (_openBlock(), _createBlock(_component_WaitForResource, {
    "hide-error": "",
    resource: _ctx.user,
    "show-error-details": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('users.create.title')), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('users.create.body')), 1),
      _createElementVNode("form", {
        class: "mt-12",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.saveProfile && _ctx.saveProfile(...args)), ["prevent"]))
      }, [
        _createVNode(_component_ImageField, {
          class: "mt-12",
          errors: _ctx.user.errors,
          label: _ctx.$t('users.edit.profile.picture'),
          "model-value": _ctx.image,
          name: "profile-picture",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.newProfilePicture = $event))
        }, null, 8, ["errors", "label", "model-value"]),
        _createVNode(_component_FormGroup, {
          modelValue: _ctx.user.firstName,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.firstName) = $event)),
          class: "mt-12",
          "error-key": "firstName",
          errors: _ctx.user.errors,
          label: _ctx.$t('users.edit.profile.firstName'),
          name: "firstName"
        }, null, 8, ["modelValue", "errors", "label"]),
        _createVNode(_component_FormGroup, {
          modelValue: _ctx.user.lastName,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.lastName) = $event)),
          class: "mt-12",
          "error-key": "lastName",
          errors: _ctx.user.errors,
          label: _ctx.$t('users.edit.profile.lastName'),
          name: "lastName"
        }, null, 8, ["modelValue", "errors", "label"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_BtnResource, { resource: _ctx.user }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('actions.next')), 1)
            ]),
            _: 1
          }, 8, ["resource"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["resource"]))
}