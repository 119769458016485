
import {defineComponent, ref} from 'vue';
import BtnResource from '@/components/common/model-collection/BtnResource.vue';
import {DefaultConversions} from '@/models/Media';
import FormGroup from '@/components/common/form/FormGroup.vue';
import ImageField from '@/components/common/form/ImageField.vue';
import Team from '@/models/Team';
import User from '@/models/User';
import authStore from '@/store/auth';

export default defineComponent({
    components: {
        BtnResource,
        FormGroup,
        ImageField,
    },
    setup() {
        const newUser = new User({
            id: 'me',
        });

        return {
            user: ref(newUser),
            newProfilePicture: ref<string>(''),
        };
    },
    computed: {
        image(): string | DefaultConversions | undefined {
            return this.newProfilePicture || this.user.profilePicture;
        },
    },
    mounted() {
        this.user.fetch();
    },
    methods: {
        async saveProfile() {
            if (this.newProfilePicture) {
                this.user.profilePicture = {
                    original: this.newProfilePicture,
                };
            }

            try {
                await this.user.update();
            } catch (e: any) {
                if (!e.response || !e.response.data.errors) throw e;

                return;
            }

            // Update the user in the store with the new data.
            if (authStore.me.value) {
                authStore.me.value.fill(this.user.attributes);
            }

            if (
                this.$route.query.teamId
                && this.$route.query.teamType
            ) {
                const team = new Team({
                    id: +this.$route.query.teamId,
                    type: +this.$route.query.teamType,
                });

                await this.$router.push(team.getLocation('register'));

                return;
            }

            await this.$router.push({name: 'home'});
        },
    },
});
